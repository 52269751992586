<template>
  <div>
    <h2 class="mb-4">Ключевые характеристики программы</h2>
    <div class="section__acceleration-key container-grid">
      <div class="container-grid__item">
        <div class="container-shadow p-24 fill-white">
          <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M53.3438 42.9483H63.522V47.1671H53.3438V42.9483Z" fill="#1C75BC"/>
            <path d="M53.3438 51.074H63.522V55.2928H53.3438V51.074Z" fill="#1C75BC"/>
            <path
                d="M18.2647 6.38021L11.256 0.195801L3.89011 6.34519V46.0847H0V50.3034H5.00948V60.3099C5.00948 63.6558 7.73156 66.3779 11.0775 66.3779C14.4233 66.3779 17.1456 63.6558 17.1456 60.3099V50.3034H22.155V46.0847H18.2648V6.38021H18.2647ZM11.18 5.75485L14.0459 8.28371V46.0846H8.109V8.31886L11.18 5.75485ZM11.0775 62.1591C10.0578 62.1591 9.22823 61.3296 9.22823 60.3099V58.0551H12.9268V60.3099C12.9268 61.3296 12.0971 62.1591 11.0775 62.1591ZM12.9268 53.8362H9.22823V50.3033H12.9268V53.8362Z"
                fill="#1C75BC"/>
            <path
                d="M60.8506 27.2374C60.916 26.5251 60.9507 25.8097 60.9507 25.0945V10.4507L59.3181 10.0718C56.4129 9.39783 54.3838 6.84422 54.3838 3.86184V2.23102L49.8317 1.04667C44.4677 -0.348891 38.7974 -0.348891 33.4334 1.04667L28.8812 2.23102V3.86184C28.8812 6.84422 26.8521 9.39783 23.9471 10.0718L22.3145 10.4507V25.0945C22.3145 29.8171 23.721 34.3752 26.3819 38.276C28.7597 41.7617 31.9924 44.5033 35.7875 46.2767V66.031H35.8059C36.0573 69.3647 38.8501 72 42.2469 72H72.0002V27.2374H60.8506ZM50.294 5.526C50.8662 9.18731 53.3026 12.2653 56.7321 13.6634V25.0944C56.7321 25.8102 56.6908 26.5262 56.6111 27.2372H43.9947V4.32014C45.6031 4.45402 50.294 5.526 50.294 5.526ZM42.2469 59.0818C41.4596 59.0818 40.7048 59.2234 40.0065 59.4824V33.3332C40.0065 32.2982 40.8485 31.4561 41.8835 31.4561H44.3888V59.0808L42.2469 59.0818ZM29.8673 35.8986C27.6863 32.7015 26.5335 28.9654 26.5335 25.0945V13.6635C29.963 12.2655 32.3992 9.18745 32.9716 5.52614C32.9716 5.52614 36.4176 4.2802 39.7761 4.2802V27.6133C37.4505 28.4729 35.7879 30.7124 35.7879 33.3332V41.5024C33.4465 40.0895 31.431 38.1909 29.8673 35.8986ZM67.7815 67.7812H42.2469C41.0115 67.7812 40.0065 66.7763 40.0065 65.5408C40.0065 64.3053 41.0115 63.3004 42.2479 63.3004L67.7815 63.2887V67.7812ZM67.7815 59.07L48.6075 59.0788V31.4561H67.7815V59.07Z"
                fill="#1C75BC"/>
          </svg>
          <h2>Практикоориентированность</h2>
          <div class="subtitle">
            Ведущие эксперты программы имеют совокупный опыт работы в экспорте более чем в 50 лет, причем за плечами преподавателей – работа как непосредственно с международными поставками, так и консалтинговые практики.
          </div>
        </div>
      </div>
      <div class="container-grid__item">
        <div class="container-shadow p-24 fill-white">
          <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3130:2892)">
              <path
                  d="M66.375 42.8566V31.1664L38.1097 19.4043V16.0701H50.2864V3.63052H38.1097V0H33.8909V19.4043L5.625 31.1664V42.8566H9.06469V59.1846H5.625V72H66.375V59.1847H62.7891V42.8567H66.375V42.8566ZM46.0676 7.84927V11.8512H38.1097V7.84927H46.0676ZM49.4716 59.1847H42.6587V42.8567H49.4716V59.1847ZM22.382 42.8566H29.3413V59.1846H22.382V42.8566ZM9.84375 34.6828H62.1562V38.6378H9.84375V34.6828ZM18.2939 30.464L36.0003 23.096L53.7062 30.464H18.2939ZM33.56 59.1847V55.2509H38.4398V59.1847H33.56ZM38.44 51.0321H33.5602V42.8566H38.44V51.0321ZM18.1633 42.8566V51.0321H13.2834V42.8566H18.1633ZM13.2834 55.2509H18.1633V59.1847H13.2834V55.2509ZM62.1562 67.7812H9.84375V63.4035H62.1562V67.7812ZM53.6903 59.1847V55.2509H58.5703V59.1847H53.6903ZM58.5703 51.0321H53.6903V42.8566H58.5703V51.0321Z"
                  fill="#1C75BC"/>
            </g>
            <defs>
              <clipPath id="clip0_3130:2892">
                <rect width="72" height="72" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <h2>Компактность</h2>
          <div class="subtitle">
            Обучение по программе занимает всего 2 дня. Занятия проходят в очном формате с 10:00 до 18:00 на площадке Школы экспорта по адресу <b>г. Москва, Краснопресненская набережная, д.12.</b>
          </div>
        </div>
      </div>
      <div class="container-grid__item">
        <div class="container-shadow p-24 fill-white">
          <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M65.6956 15.6445C66.337 14.6319 66.7103 13.4332 66.7103 12.1485C66.7103 8.53481 63.7705 5.59505 60.1568 5.59505C58.9015 5.59505 57.7281 5.95041 56.7304 6.56494C50.6485 2.26505 43.5178 0 36 0C26.3841 0 17.3437 3.7447 10.5442 10.5441C3.7447 17.3437 0 26.3841 0 36C0 43.5268 2.27053 50.6661 6.58069 56.7529C6.00145 57.7298 5.66761 58.8686 5.66761 60.0843C5.66761 63.6979 8.60738 66.6377 12.221 66.6377C13.4667 66.6377 14.6322 66.2881 15.625 65.6823C21.6314 69.8203 28.64 72 36 72C45.6159 72 54.6563 68.2553 61.4559 61.4559C68.2553 54.6563 72 45.6159 72 36C72 28.6478 69.825 21.6465 65.6956 15.6445ZM60.1568 9.82041C61.4406 9.82041 62.485 10.8648 62.485 12.1485C62.485 13.4321 61.4406 14.4765 60.1568 14.4765C58.8732 14.4765 57.8288 13.4321 57.8288 12.1485C57.8288 10.8648 58.8731 9.82041 60.1568 9.82041ZM12.221 62.4125C10.9373 62.4125 9.89297 61.368 9.89297 60.0844C9.89297 58.8008 10.9374 57.7564 12.221 57.7564C13.5048 57.7564 14.5491 58.8008 14.5491 60.0844C14.5491 61.368 13.5048 62.4125 12.221 62.4125ZM58.4681 58.4681C52.4668 64.4695 44.4873 67.7746 36 67.7746C29.6356 67.7746 23.5696 65.9291 18.3421 62.421C18.6204 61.6947 18.7743 60.9074 18.7743 60.0843C18.7743 56.4706 15.8345 53.5309 12.2209 53.5309C11.3685 53.5309 10.5546 53.6961 9.80691 53.9935C6.14981 48.6934 4.22536 42.5114 4.22536 36C4.22536 27.5127 7.53047 19.5334 13.5319 13.5319C19.5334 7.53047 27.5126 4.22536 36 4.22536C42.5257 4.22536 48.7202 6.15839 54.028 9.83109C53.7545 10.5521 53.6034 11.3328 53.6034 12.1485C53.6034 15.7621 56.5433 18.7019 60.1568 18.7019C60.9434 18.7019 61.698 18.5624 62.3973 18.3071C65.9206 23.5425 67.7748 29.6211 67.7748 36C67.7746 44.4873 64.4695 52.4666 58.4681 58.4681Z"
                fill="#1C75BC"/>
            <path
                d="M35.9993 12.321C35.1516 12.321 34.3028 12.3668 33.4613 12.4564C32.2909 10.6619 30.2672 9.47235 27.9695 9.47235C24.3558 9.47235 21.4161 12.4121 21.4161 16.0258C21.4161 16.4452 21.4573 16.855 21.5329 17.2527C15.7379 21.7273 12.3203 28.6276 12.3203 36C12.3203 49.0566 22.9426 59.679 35.9993 59.679C49.0559 59.679 59.6783 49.0567 59.6783 36C59.6783 22.9432 49.0559 12.321 35.9993 12.321ZM27.9695 13.6977C29.2533 13.6977 30.2975 14.7421 30.2975 16.0258C30.2975 17.3095 29.2531 18.3538 27.9695 18.3538C26.6859 18.3538 25.6414 17.3094 25.6414 16.0258C25.6413 14.7421 26.6857 13.6977 27.9695 13.6977ZM35.9993 55.4536C25.2726 55.4536 16.5457 46.7267 16.5457 36C16.5457 30.1383 19.1756 24.6393 23.6614 20.958C24.8143 21.9663 26.3212 22.5793 27.9695 22.5793C31.3878 22.5793 34.2016 19.9481 34.4959 16.6049C34.9957 16.5667 35.4977 16.5464 35.9993 16.5464C46.726 16.5464 55.453 25.2733 55.453 36C55.453 46.7267 46.726 55.4536 35.9993 55.4536Z"
                fill="#1C75BC"/>
            <path
                d="M41.9545 33.0892L45.0445 29.9992L42.0568 27.0115L38.9862 30.0819C38.7058 29.9398 38.4143 29.8171 38.1128 29.7155V25.3777H33.8875V29.7155C33.5861 29.8171 33.2944 29.9398 33.014 30.0819L29.9436 27.0115L26.9559 29.9992L30.0459 33.0892C29.9113 33.3637 29.796 33.649 29.6999 33.9432H25.3223V38.1686H29.7408C29.8383 38.4486 29.9542 38.72 30.0868 38.9815L26.9559 42.1124L29.9436 45.1001L33.094 41.9497C33.3497 42.075 33.6149 42.1831 33.8876 42.2751V46.7338H38.113V42.2752C38.3856 42.1833 38.6508 42.0751 38.9064 41.9498L42.0568 45.1001L45.0445 42.1124L41.9136 38.9815C42.0462 38.72 42.1621 38.4486 42.2596 38.1686H46.6781V33.9432H42.3006C42.2044 33.649 42.089 33.3637 41.9545 33.0892ZM38.4019 35.9954C38.4019 37.3196 37.3246 38.3971 36.0002 38.3971C34.6759 38.3971 33.5985 37.3196 33.5985 35.9954C33.5985 34.6711 34.6759 33.5936 36.0002 33.5936C37.3246 33.5936 38.4019 34.6711 38.4019 35.9954Z"
                fill="#1C75BC"/>
          </svg>
          <h2>Стоимость и что в нее входит</h2>
          <div class="subtitle">
            Стоимость участия в программе – <b class="text-danger">50 000 руб., но только для участников пилотного потока обучения составляет 40 000 руб. и включает в себя</b>:
            <ul class="list list_dotted">
              <li>раздаточные материалы (с возможностью их повторного просмотра после обучения)</li>
              <li>кофе-брейки (3 раза каждый день обучения)</li>
              <li>удостоверение о повышении квалификации по итогам участия в программе</li>
              <li>«живое» общение с опытными экспертами, которые ответят на все вопросы.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OfflineNewKeyCharacter"
}
</script>

<style scoped>

</style>