<template>
  <div>
    <div class="section section__acceleration section__how-learn" style="padding-bottom: 0;">
      <div class="section__wrapper">
        <div class="title-with-icon mb-4">
          <div class="title-with-icon__icon title-with-icon__icon_how-learn"></div>
          <div class="title-with-icon__title">Офлайн-обучение. Новый экспорт в текущих условиях</div>
        </div>
        <div class="row align-items-stretch">
          <div class="col section__acceleration-description mb-5">
            <div class="section__acceleration-description-text mb-5">
              <p class="content_22">Вы планируете начать экспортировать или закрепиться на зарубежных рынках, но не уверены, насколько это перспективно в текущих условиях, и нужен ли вашему бизнесу экспорт вообще?</p>
              <p class="content_22">Программа очного обучения <b class="text-blue">«Новый экспорт в текущих условиях»</b> поможет вам в кратчайшие сроки сформировать четкое видение экспорта как стратегического проекта, направленного на развитие бизнеса с учетом актуальных условий. Наша программа мотивирует вас к принятию взвешенных решений о будущем компании в ее международном развитии.</p>
              <p class="content_22">Обучение по программе <b class="text-blue">длится 2 дня и состоит из 3 тематических блоков</b>.</p>
            </div>
            <h2 class="mt-1 mb-2">В ходе обучения участники:</h2>
            <div class="ffg__mission__list blue mb-2">
              <ul class="blue-label">
                <li>научатся использовать аналитические инструменты для определения перспективных рынков и корректировки стратегии экспортной деятельности</li>
                <li>изучат альтернативные способы выхода на экспорт для диверсификации рынков</li>
                <li>выявят точки роста для своего бизнеса</li>
                <li>сформулируют задачи для развития экспортного направления компании</li>
              </ul>
            </div>
            <h2 class="mt-1 mb-2">Ключевая ценность программы</h2>
            <div class="ffg__mission__list blue mb-2">
              <ul class="blue-label">
                <li>Программа поможет за короткое время получить четкое представление о готовности своего бизнеса к работе на международных рынках с учетом текущих условий.</li>
                <li>В качестве результата участники программы разработают предварительный план (концепцию) для трансформации стратегии бизнеса с включением экспортного направления.</li>
              </ul>
            </div>
            <h2 class="mb-1">
              Целевая аудитория
            </h2>
            <p class="content_22">
              Если вы – владелец компании, ее топ-менеджер, или другое ответственное лицо, участвующее в планировании бизнес-стратегии, то программа <b class="text-blue">«Новый экспорт в текущих условиях»</b> для вас!
            </p>
            <p class="content_22">
              При этом вы можете работать как в неэкспортирующей компании, успешно развивающейся на внутреннем рынке и ищущей новые возможности для дальнейшего роста, так и в компании-экспортере, находящейся в замешательстве относительно своего экспортного будущего из-за текущей ситуации на международном рынке.
            </p>
            <p class="content_22">
              Также участниками программы могут стать и физические лица, желающие повысить свои компетенции в экспорте.
            </p>
          </div>
          <div class="col-md-4 mb-3 mb-md-0 section__acceleration-tag">
            <div class="container-shadow p-24 fill-white">
              <div class="section__acceleration-tag-img mb-24">
                <img src="/pic/export-incease.png" class="object-fit-cover"/>
              </div>
              <div class="section__acceleration-tag-title">
                Двухдневный очный интенсив от Школы экспорта РЭЦ, формирующий представление об экспортной деятельности в нынешних условиях применительно к Вашему бизнесу.
              </div>
              <div>
                <a href="https://shkola-eksp-2024.timepad.ru/event/3120598/" target="_blank" class="link-target">
                  <span>Зарегистрироваться</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <offline-new-key-character />
        <offline-new-content />
        <h2 class="mb-1">
          Контакты
        </h2>
        <div class="container-grid grid-md-2">
          <div class="container-grid__item">
            <div class="container-shadow fill-white p-24">
              <div class="lead" itemprop="rucovodstvoZam">
                <div class="lead__image">
                  <div class="avatar avatar_big container-shadow" :style="{backgroundImage: 'url(/images/mutalib2.png)'}"></div>
                </div>
                <div class="lead__content content_22">
                  <div class="content_subtitle content_700 mb-24" itemprop="fio">Муталиб Абдулгамидович Курахов</div>
                  <div class="lead__content-item mb-24">
                    <div class="content_blue_light">Должность</div>
                    <div class="lead__content-data" itemprop="post">Директор по организации обучения</div>
                  </div>
                  <div class="lead__content-item">
                    <div class="content_blue_light">Контактная информация</div>
                    <div class="lead__content-data">
                      <div itemprop="telephone">
                        +7 (495) 937-47-47 (доб. 6675)
                      </div>
                      <div itemprop="telephone">
                        +7 (906) 786-86-36
                      </div>
                      <div><a class="content_red content_underline" itemprop="email"
                              href="mailto:kurahov@exportcenter.ru">kurahov@exportcenter.ru</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import OfflineNewKeyCharacter from "@/components/offlinenew/OfflineNewKeyCharacter.vue";
import OfflineNewContent from "@/components/offlinenew/OfflineNewContent.vue";

export default {
  name: "OfflineNewExport",
  components: {OfflineNewContent, OfflineNewKeyCharacter},
  mounted() {
    this.$parent.init()
  }
}
</script>

<style lang="scss" scoped>

</style>