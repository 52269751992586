<template>
  <div class="section__acceleration-content mt-5">
    <h2 class="mb-5">Содержание образовательной программы</h2>
    <div class="section__acceleration-content-steps">
      <div class="section__acceleration-content-step">
        <div class="section__acceleration-content-step-wrapper">
          <h2>Блок 1: Экспорт в условиях глобальной геополитической турбулентности</h2>
          <div class="item-content">
            В этом блоке вы изучите влияние санкций на товарные потоки и особенности экономических кризисов. Практические задания помогут освоить анализ экспортных перспектив и долгосрочных трендов до 2030 года. Узнайте, как кризисы могут стать уроками и возможностями для ваших экспортных инициатив.
          </div>
        </div>
      </div>
      <div class="section__acceleration-content-step">
        <div class="section__acceleration-content-step-wrapper">
          <h2>Блок 2: Экспортная стратегия как часть стратегии компании</h2>
          <div class="item-content">
            Данный блок ориентирован на интеграцию экспортной стратегии в общую стратегию компании. Вы получите знания о рисках экспортной деятельности и разработаете стратегические цели для развития экспортного направления. Практические задачи помогут систематизировать подход к организации экспорта и минимизировать риски.
          </div>
        </div>
      </div>
      <div class="section__acceleration-content-step">
        <div class="section__acceleration-content-step-wrapper">
          <h2>Блок 3: Особенности реализации различных вариантов экспортных стратегий</h2>
          <div class="item-content">
            Этот блок познакомит вас с актуальными моделями интернационализации бизнеса и адаптацией к текущим условиям рынка. Блок включает практические работы, направленные на переориентацию экспорта и приоритизацию рынков. Вы также научитесь учитывать специфические страновые факторы и разрабатывать адаптированные экспортные стратегии для вашей компании.
          </div>
        </div>
      </div>
    </div>
    <div class="notice notice_red mb-3 mt-5">
      <h2 class="notice__title text-danger">Важно!</h2>
      <div class="notice__content">
        <p class="content_18">Программа предусматривает работу с различными формами экспортной деятельности – <br/>если вы не планируете осуществлять прямые торговые поставки, вы узнаете о:</p>
        <ul>
          <li>создании совместных производств за рубежом</li>
          <li>продаже интеллектуальной собственности</li>
          <li>процессах создания автономных филиалов</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OfflineNewContent"
}
</script>

<style scoped>

</style>